import * as React from 'react';
import { useState, useEffect/*, useCallback, useMemo*/ } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CircularProgress } from '@mui/material';

import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

// utils
import {NetworkOperations, CopyRight, SearchBar, Header, ItemDataType} from './infrastructure';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import he from 'he';
import { ContentPage } from './infrastructure/components';


function SimpleGovContent({news, queries, agendas, setSelectedItem, isLoading, selectedDataType, handleTabDataTypeChange, lodaMoreDataType}) {
  
  const { t } = useTranslation();
  
  const generateData = (data, category, linkToUrl, subjectFieldKey, itemIDKey, contentFieldKey, itemUri, subjectLength = 50) => {
    
    let truncatedText;
    return data.map((dataItem) => {
      truncatedText = dataItem[subjectFieldKey] !== undefined ? he.decode(dataItem[subjectFieldKey].substr(0, subjectLength)) : '';
      const itemURL = `${category}/${dataItem[itemIDKey]}`;
      return <Grid item key={`${category}-${dataItem[itemIDKey]}`} xs={12} sm={6} md={4} lg={3}>
                <Card key={`${category}-${dataItem[itemIDKey]}`}>
                  <CardContent  sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}>
                    <Typography variant="body1" component="div" dir="rtl">
                      {truncatedText}
                      {dataItem[subjectFieldKey] !== undefined ? dataItem[subjectFieldKey].length >= subjectLength ? "...": "" : ""}                    
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button onClick={()=>setSelectedItem(() => {return {title: dataItem[subjectFieldKey], body: dataItem[contentFieldKey]};})}>
                      <Link to={itemURL}>
                        {t('common.loading')}
                      </Link>
                    </Button>   
                    {selectedDataType === ItemDataType.news.key && dataItem.News_Url !== undefined && dataItem.News_Url.length > 0 && <Button onClick={() => openLink('https://main.knesset.gov.il/',dataItem.News_Url)}>
                      {t('common.open_article')} 
                    </Button>}
                    {selectedDataType === ItemDataType.query.key && <Button onClick={() => openLink('https://main.knesset.gov.il', itemUri+dataItem[itemIDKey])}>
                      {t('common.open_article')}
                    </Button>}
                    {selectedDataType === ItemDataType.agenda.key && <Button onClick={() => openLink('https://main.knesset.gov.il', itemUri+dataItem[itemIDKey])}>
                      {t('common.open_article')}
                    </Button>}
                  </CardActions>
              </Card>
        </Grid>
    })
  };

  const openLink=(prefixUri, uri) => {
     window.open(prefixUri+uri, '_blank', 'noreferrer');
  };

  const TabPanel = ({ value, index, children }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        style={{marginTop:40}}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const OrderedTabs = (tabs, selectedDataType, handleTabDataTypeChange) => {
    return (
      <React.Fragment>
        <Tabs value={selectedDataType} onChange={handleTabDataTypeChange} centered>
            {tabs.map((tab, index) => <Tab key={`tab-${tab.title}-${index}`} label={`${t(tab.title)}`} value={tab.dataType} />)}
        </Tabs>
        {tabs.map((tab, index) => <TabPanel key={`tabPanel-${tab.title}-${index}`} value={selectedDataType} index={index} >
            <Grid container spacing={5} justifyContent="space-evenly">
              {tab.content !== undefined && typeof  tab.content === "function" ? tab.content() : tab.content}
            </Grid>
        </TabPanel>)}
      </React.Fragment>
    );
  };

  const containerContent = () => {

    const getItems = (itemType) => { 
      switch(itemType) {
        default:
        case ItemDataType.news.key:
          return news;
        case ItemDataType.query.key:
          return queries;
        case ItemDataType.agenda.key:
          return agendas;
      }
    } 

    const tabs = Object.values(ItemDataType).map(item => {
      return {
        title: item.title,
        dataType: item.key,
        content: ()=> generateData(getItems(item.key), item.category , `/${item.title}`, item.subjectFieldKey, item.itemIDKey, item.contentFieldKey, item.itemUri)
      }
    });
  
   return <React.Fragment>
      {OrderedTabs(tabs, selectedDataType, handleTabDataTypeChange )}
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
        {getItems(selectedDataType).length > 0 && <Button variant="contained" color="primary" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={lodaMoreDataType}>
          {t('common.loading')}
        </Button>}
      </Box>
      {isLoading && 
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: `${getItems(selectedDataType).length > 0 ? '20' :'60'}vh`,
          }}
        >
          <CircularProgress />
        </Box>}
   </React.Fragment>
  };
  
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Container maxWidth="xl" component="main">
      {containerContent()}
      </Container>
      {/* Footer */}
      <Container
        maxWidth="xl"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <CopyRight sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

const knewsetAPICall = async (instance, actionName ,uri, subject, from, to, page) => {
  let data;
  try {
    data = await instance[actionName](uri, subject, from, to, page, 10);
  } catch(err) {
    console.error(err);
  }
  return data;
}


export default function SimpleGov() {
  
  const location = useLocation();
  const currentPath = location.pathname;

  const [newsItems, setNewsItems] = useState([]);
  const [queryItems, setQueryItems] = useState([]);
  const [agendaItems, setAgendaItems] = useState([]);

  const [selectedItem, setSelectedItem] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [selectedDataType, setSelectedDataType] = useState(ItemDataType.news.key);
  const [newsCurrentPage, setNewsCurrentPage] = useState(null);
  const [queryCurrentPage, setQueryCurrentPage] = useState(null);
  const [agendaCurrentPage, setAgendaCurrentPage] = useState(null);

  const handleTabDataTypeChange = (event, newDataType) => {
    try {
      const instance = NetworkOperations('https://knesset.gov.il/WebSiteApi/knessetapi/');
      if(newDataType === ItemDataType.query.key && queryItems.length === 0) {
        (async () => {
          setIsLoading(true);
          const queryResponse = await knewsetAPICall(instance, 'query', 'Query/GetQueryResults');
          setQueryItems(()=> queryResponse);
          setIsLoading(false);
        })()
      }
      if(newDataType === ItemDataType.agenda.key && agendaItems.length === 0) {
        (async () => {
          setIsLoading(true);
          const agendaResponse = await knewsetAPICall(instance, 'agenda', 'Agenda/GetAgendaResults');
          setAgendaItems(()=> agendaResponse);
          setIsLoading(false);
        })()
      }
    }
    catch (err) {
      console.error(err);
    } finally {
      setSelectedDataType(newDataType);
    }
  };

  useEffect(()=>{
    setIsLoading(true);
    (async()=>{
      try {
        // TODO: change into object calling and invoking data update 
        const instance = NetworkOperations('https://knesset.gov.il/WebSiteApi/knessetapi/');

        const newsResponse = await knewsetAPICall(instance, 'news', 'KnessetMainLobby/GetKnessetLobbyNews');
        setNewsItems(()=> newsResponse.KnessetNewsData);

      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }

    })()
  }, []);

  const lodaMoreDataType = async (event ,page = 2) => {
    let currentPageField, currentSetterField, currentOptions, response, currentData, currentSetterData, newData;
    const instance = NetworkOperations('https://knesset.gov.il/WebSiteApi/knessetapi/');
    switch(selectedDataType) {
      default:
      case ItemDataType.news.key:
        currentData = newsItems;
        currentSetterData = setNewsItems;
        currentPageField = newsCurrentPage;
        currentSetterField = setNewsCurrentPage;
        currentOptions = ItemDataType.news;
      break;
      case ItemDataType.query.key:
        currentData = queryItems;
        currentSetterData = setQueryItems;
        currentPageField = queryCurrentPage;
        currentSetterField = setQueryCurrentPage;
        currentOptions = ItemDataType.query;
      break;
      case ItemDataType.agenda.key:
        currentData = agendaItems;
        currentSetterData = setAgendaItems;
        currentPageField = agendaCurrentPage;
        currentSetterField = setAgendaCurrentPage;
        currentOptions = ItemDataType.agenda;
      break;
    }
          
    currentSetterField( currentPageField !== null ? ++currentPageField : 2)
    setIsLoading(true);
    response = await knewsetAPICall(instance, currentOptions.name, currentOptions.api, null, null, null, currentPageField)
    if(currentOptions.responseFiled === null) {
      newData = response;
    } else {
      newData = response[currentOptions.responseFiled];
    }
    currentSetterData(()=>[...currentData, ...newData]);
    setIsLoading(false);
  }

  // TODO: fix routes per item type 

  return <React.Fragment>
      <Header title={'simpleGov'} showBackButton={currentPath !== '/'} />
      <Routes>
        <Route path="/"
          element={<SimpleGovContent 
            news={newsItems}
            queries={queryItems}
            agendas={agendaItems}
            isLoading={isLoading}
            selectedDataType={selectedDataType}
            handleTabDataTypeChange={handleTabDataTypeChange}
            setSelectedItem={setSelectedItem} 
            lodaMoreDataType={lodaMoreDataType}
          />} 
        />
        {Object.values(ItemDataType).map((item) => 
          <Route key={`/${item.category}/:id` } 
          path={`/${item.category}/:id` }
          element={<ContentPage item={selectedItem} itemType={item.name}/>}
        />)}
      </Routes>
    </React.Fragment>
}
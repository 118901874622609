const NetworkOperations = (prefixUrl) => {

    const headersDRY = {
        "accept": "application/json, text/javascript, */*; q=0.01",
        "accept-language": "en-US,en;q=0.9",
        "content-type": "application/json",
        "sec-fetch-mode": "cors",
    };

    const method = {"method": "POST"};

    return {
      // TODO: check GetNewsCmbData
      // TODO: check json seralize check 
      news: async (uri, subject = null, from = null, to = null, pageNumber = null, rowsPerPage = 10) => {
          const response = await fetch(prefixUrl+uri, {
              "headers": {...headersDRY},
              "body": "{\"Lang\":\"he\",\"Subject\":"+subject+",\"FromDate\":"+from+",\"ToDate\":"+to+",\"PageNumber\":"+pageNumber+",\"RowsPerPage\":"+rowsPerPage+"}",
              ...method
            });
            return await response.json(); // parses JSON response into native JavaScript objects
      },
      // GetQueryCmbData
      query: async (uri, subject = null, from = null, to = null, pageNumber = null, rowsPerPage = 10) => { 
        const response = await fetch(prefixUrl+uri, {
            "headers": {...headersDRY},
            "body": "{\"BeginDate\":"+from+",\"EndDate\":"+to+",\"GovMinistryIDs\":\"\",\"KnessetIDs\":\"\",\"MkIDs\":\"\",\"PageNumber\":"+pageNumber+",\"QueryNumber\":null,\"RowsPerPage\":"+rowsPerPage+",\"Sort\":\"d\",\"StatusIDs\":\"\",\"Subject\":\"\",\"TypesIDs\":\"\"}",
            ...method
          });
          return await response.json(); // parses JSON response into native JavaScript objects
      },
      // GetAgendaCmbData
      agenda: async (uri, subject = null, from = null, to = null, pageNumber = null ) => {
        const response = await fetch(prefixUrl+uri, {
            "headers": {...headersDRY},
            "body": "{\"PageNumber\":" +pageNumber+ "}",
            ...method
          });
          return await response.json(); // parses JSON response into native JavaScript objects
      },
    }


};

export default NetworkOperations;

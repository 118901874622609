import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <IconButton color="primary" onClick={handleGoBack}>
      <ArrowBackIcon />
    </IconButton>
  );
};

function Header({title, showBackButton}) {
    return <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          {/* Get from i18n as title  */}
          {showBackButton && <BackButton/>}
          {title}
          
        </Typography>
      </Toolbar>
    </AppBar>
  }

  export default Header;
// fixing loading data 
const ItemDataType = {
    agenda: {
        key: 0,
        name: 'agenda',
        category:'agenda',
        api: 'Agenda/GetAgendaResults',
        title: 'common.agenda',
        responseFiled: null,
        subjectFieldKey: 'Subject',
        itemIDKey: 'ItemID',
        contentFieldKey: 'News_Content',
        itemUri: '/apps/agenda/details/',
        urlKey: undefined
    },
    query:{
        key: 1, 
        name: 'query',
        category:'queries',
        api: 'Query/GetQueryResults',
        title: 'common.query',
        responseFiled: null,
        subjectFieldKey: 'QuestionSubject',
        itemIDKey: 'ItemID',
        contentFieldKey: 'News_Content',
        itemUri: '/apps/query/details/',
        urlKey: undefined
    },
    news:{
        key: 2,
        name: 'news',
        category:'news',
        api: 'KnessetMainLobby/GetKnessetLobbyNews',
        title: 'common.news',
        responseFiled: 'KnessetNewsData',
        subjectFieldKey: 'Message_Subject', 
        itemIDKey: 'SP_Id',
        contentFieldKey: 'News_Content',
        itemUri: undefined,
        urlKey: 'News_Url'
    },
    // search:{
    //     key: 3,
    //     name: 'search',
    //     category:'search',
    //     // api: 'KnessetMainLobby/GetKnessetLobbyNews',
    //     title: 'common.news',
    //     // responseFiled: 'KnessetNewsData',
    //     // subjectFieldKey: 'Message_Subject', 
    //     // itemIDKey: 'SP_Id',
    // }
};

export default ItemDataType;
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import he from 'he';


function ContentPage({item, itemType}) {
console.log("🚀 ~ file: contentPage.jsx:9 ~ ContentPage ~ item:", item)

    return (
      <Box sx={{display: 'flex',  direction: 'rtl', maxWidth:'80%'}}>
        <div className="Content-page-container">
            <div className="Content-page-centered-content">
                <h3> {item['title']}</h3>
                <p>{item['body'] !== undefined ? he.decode(item['body']) : ''}</p>
            </div>
        </div>
      </Box>
    );
  }
  
  export default ContentPage;
